<template>
	<v-layout v-if="model" class="slot-container">
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-layout
					:style="`width: ${displayAvatarSize}px; height: ${displayAvatarSize}px; border-radius: 50%;`"
					v-bind="$attrs"
					class="primary white--text"
					align-center
					justify-center
					v-on="on"
					>{{ abbreviation }}</v-layout
				>
			</template>
			<span v-text="name" />
		</v-tooltip>
		<v-flex class="badge-slot top left" :style="displayBadgeStyle">
			<slot :style="displayBadgeStyle" name="topLeftBadge"></slot>
		</v-flex>
		<v-flex class="badge-slot top right" :style="displayBadgeStyle">
			<slot :style="displayBadgeStyle" name="topRightBadge"></slot>
		</v-flex>
	</v-layout>
</template>
<script>
export default {
	name: 'CompanyAvatar',
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			displayAvatarSize: '40',
			displayBadgeSize: '20'
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		company: function () {
			return this.model ? this.model : null
		},
		abbreviation: function () {
			return this.company ? this.company.abbreviation : null
		},
		name: function () {
			return this.company?.client_code ? `${this.company.company} (${this.company.client_code})` : this.company ? this.company.company : ''
		},
		displayBadgeStyle: function () {
			return `
				width: ${this.displayBadgeSize}px;
				height: ${this.displayBadgeSize}px;
				min-width: ${this.displayBadgeSize}px;
				min-height: ${this.displayBadgeSize}px;
				max-width: ${this.displayBadgeSize}px;
				max-height: ${this.displayBadgeSize}px;
			`
		}
	}
}
</script>

<style scoped>
.slot-container {
	position: relative;
}
.badge-slot {
	position: absolute;
	height: 20%;
	width: 20%;
	max-width: 20%;
	max-height: 20%;
}
.top {
	top: 0;
	transform: translate(-50%, -50%);
}

.left {
	left: 0;
	transform: translate(-50%);
}

.right {
	right: 0;
	transform: translate(50%);
}

.bottom {
	bottom: 0;
	transform: translate(50%);
}
</style>
